export const environment = {
  production: false,
  hmr: false,
  keys: {
    token: 'TOKEN_PROXY_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    user: 'USER_PROXY_KEY',
  },
  config: {
    redirectToWhenAuthenticated: '/pages/dashboard',
    redirectToWhenUnauthenticated: '/auth/login',
  },
  front: {
    baseUrl: 'https://backoffice-qas.ecologicatech.ligafacens.com',
  },
  api: {
    baseUrl: 'https://api-qas.ecologicatech.ligafacens.com',
  },
};
